import { useState } from "react";

interface GuessBagPageInterface {
  handleGuessSubmit: (result: number) => Promise<void>,
  guessGameInputPlaceholder?: string
}

function GuessGame({handleGuessSubmit, guessGameInputPlaceholder} : GuessBagPageInterface): JSX.Element {
  const [guessInput, setGuessInput] = useState('');
  const [hasError, setHasError] = useState(false);

  function handleGuessChange(e: React.ChangeEvent<HTMLInputElement>) {
    setGuessInput(e.target.value);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
      if (!guessInput) {
        setHasError(true);
        return;
      }
      handleGuessSubmit(+(guessInput));
  }

  return (
    <div className="gbag">
        <div className='registration-form__decoration-wrap'>
            <img
                className='registration-form__logo registration-form__decoration-logo'
                src='assets/images/decoration.svg'
                alt=''
            />
        </div>
        <section className='gbag__holder'>
          <div className='wrap'>
            <form className="gbag__form" onSubmit={handleSubmit} noValidate>
              <label htmlFor="guess-the-bag" className="gbag__label">Upiši svoj odgovor</label>
              <div className='gbag__input-holder'>
                <input
                    className='gbag__input registration-form__input'
                    id='guess-the-bag'
                    type='text'
                    placeholder={guessGameInputPlaceholder}
                    name='fullName'
                    value={guessInput}
                    onChange={handleGuessChange}
                />
                <span className={`gbag__placeholder ${guessInput ? 'gbag__placeholder--hidden' : ''}`}>
                  {guessGameInputPlaceholder}
                </span>
                {hasError && <span className="gbag__error">Molimo vas upišite odgovor</span>}
              </div>
              <button className="registration-form__btn"  type='submit'>
                  Potvrdi
              </button>
            </form>
          </div>
        </section>
    </div>
  )
}

export default GuessGame;