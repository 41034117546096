import {Game} from './models/Game';

export const API_URL = process.env.REACT_APP_API_URL;
export const translations: Record<Game, string> = {
    [Game.Rubiks_cube]: 'Rubikova kocka',
    [Game.Bicycle]: 'Bicikl',
    [Game.Quiz]: 'Kviz',
    [Game.Guess_bag_weight]: 'Pogodi težinu ranca',
    [Game.Tangram]: 'Tangram',
    [Game.Guess_the_number]: 'Pogodi broj M&M bombona',
}
