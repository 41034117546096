import IndexPage from '../pages/IndexPage';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ResultPage from '../pages/ResultPage';
import ThankYouPage from '../pages/ThankYouPage';

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/results' element={<ResultPage/>}/>
                <Route path='/' element={<IndexPage/>}/>
                <Route path='/thank-you' element={<ThankYouPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
