export enum Step {
    REGISTRATION = 'REGISTRATION',
    STOPWATCH = 'STOPWATCH',
    RUBIKS_CUBE = 'RUBIKS_CUBE',
    RESULT_PAGE = 'RESULT_PAGE',
    GUESS_BAG_PAGE = 'GUESS_BAG_WEIGHT',
    GUESS_THE_NUMBER = 'GUESS_THE_NUMBER',
    THANK_YOU_PAGE = 'THANK_YOU_PAGE',
    BICYCLE = 'BICYCLE',
    QUIZ = 'QUIZ',
    QUIZ_SECOND_STEP = 'QUIZ_SECOND',
    TANGRAM = 'TANGRAM'
}
