function addLeadingZero(value: number): string {
    const stringValue: string = value.toString();
    return stringValue.length < 2 ? `0${stringValue}` : stringValue;
}

function formatTime(time: number): string {
    const minutes: string = addLeadingZero(Math.floor((time % 360000) / 6000));
    const seconds: string = addLeadingZero(Math.floor((time % 6000) / 100));
    const milliseconds: string = addLeadingZero(Math.round(time % 100));

    return `${minutes}:${seconds},${milliseconds}`;
}

function formatToSeconds(time: number): number {
  return time / 100;
}

interface TimeService {
    formatTime: (time: number) => string;
    formatToSeconds: (time: number) => number;
}

const timeService: TimeService = {
    formatTime,
    formatToSeconds
};

export default timeService;
