import StarVegaIcon from "../componenets/icons/StarVegaIcon";
import { useNavigate } from "react-router-dom";

function ThankYouPage(): JSX.Element {
  const navigation = useNavigate();

  function handleConfirmClick(): void {
      navigation('/');
      localStorage.removeItem("student");
    }

  function handlePlayAgain(): void {
    navigation('/');
  }

  return (
    <div className="thank-you">
      <div className="wrap">
        <div className="thank-you__logo">
          <StarVegaIcon />
        </div>
        <h3 className="thank-you__text thank-you__text--msg">Hvala ti na učestvovanju.</h3>
        <div className="thank-you__img-holder">
          <img className="thank-you__img" src="/assets/images/vega-spark.png" alt="Vega IT Logo" />
        </div>
        <h2 className="thank-you__text thank-you__text--title">Poseti naš štand i učestvuj u dodatnim aktivacijama.</h2>
        <button className="btn thank-you__again-btn" onClick={handlePlayAgain}>Igraj ponovo</button>
        <button className="btn thank-you__complete-btn" onClick={handleConfirmClick}>Završi</button>
      </div>
    </div>
  )
}

export default ThankYouPage;